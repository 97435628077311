<template>
  <div class="warp">
    <van-form @submit="onSubmit">
      <van-field
        readonly
        clickable
        label-width="100"
        name="选择订单"
        label="选择订单"
        :value="orderList"
        placeholder="点击选择订单"
        input-align="right"
        right-icon="arrow"
        @click="showPicker0Click"
        :rules="[{ required: true, message: '请选择订单' }]"
      />
      <van-popup
        :closeable="isShowcloseable"
        :close-on-click-overlay="false"
        v-model="showPicker0"
        position="bottom"
        :style="{ height: '80%' }">
        <!-- 要选择的订单 -->
        <div class="main">
          <h3>选择订单</h3>
          <div class="mainsc">
            <div class="item-list" v-for="(item, index) in itemList" :key="index">
              <div class="left">
                <van-checkbox v-model="item.params" icon-size="24px"/>
              </div>
              <div class="right">
                <p style="font-weight: 700;">{{item.companyName}}({{item.categoryName}})</p>
                <p>订单号：{{item.orderNo}}</p>
                <p v-if="item.appointmentBeginTime && item.appointmentEndTime">订单时间：{{item.appointmentBeginTime}}-{{item.appointmentEndTime.substring(11)}}</p>
                <p style="display: flex;">
                  <span>收运完成状态：</span>
                  <van-radio-group v-model="item.orderStatus" direction="horizontal" @change="radioChange(item)">
                    <van-radio :name="5" icon-size="15px">全部收运</van-radio>
                    <van-radio :name="4" icon-size="15px">部分收运</van-radio>
                  </van-radio-group>
                </p>
              </div>
            </div>
          </div>
          <div class="btn">
            <van-button round block type="info" @click="confirmClick">确认勾选</van-button>
          </div>
        </div>
        <!-- 要选择的订单 end -->
      </van-popup>
      <van-field 
        v-model="query.productName"
        rows="2"
        autosize
        readonly
        label="固废名称"
        type="textarea"
        show-word-limit />
      <van-field
        readonly
        clickable
        label-width="100"
        name="处理方式"
        :value="handleWay"
        label="处理方式"
        placeholder="点击选择处理方式"
        @click="showPicker1 = true"
        :rules="[{ required: true, message: '请选择处理方式' }]"
      />
      <van-popup v-model="showPicker1" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns1"
          @confirm="onConfirm1"
          @cancel="showPicker1 = false"
        />
      </van-popup>
      <van-field
        readonly
        clickable
        label-width="100"
        name="接收单位"
        :value="receiveComId"
        label="接收单位"
        placeholder="点击选择接收单位"
        @click="showPicker2Click"
        :rules="[{ required: true, message: '请选择接收单位' }]"
      />
      <van-popup v-model="showPicker2" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns2"
          @confirm="onConfirm2"
          @cancel="showPicker2 = false"
        />
      </van-popup>
      <van-field
        label-width="100"
        v-model="query.signVolume"
        type="number"
        name="称重重量"
        label="称重重量(t)"
        placeholder="请输入称重重量"
        :rules="[{ required: true, message: '请填写称重重量' }]"
      />
      <van-field name="cardFront" label="上传过磅单">
        <template #input>
          <van-uploader
            v-model="uploadPaths"
            :max-count="4" />
        </template>
      </van-field>
      <van-field 
        v-model="query.remarks"
        rows="2"
        autosize
        label="备注"
        type="textarea"
        maxlength="50"
        placeholder="请输入原因"
        show-word-limit />
      <div class="btn">
        <van-button v-if="isShowbtn" round block type="info" native-type="submit">提交</van-button>
        <van-button v-else loading  type="primary" disabled block loading-text="请稍等..." />
      </div>
    </van-form>
    <div class="btn">
      <van-button round block type="default" @click="goBackClick">返回</van-button>
    </div>
  </div>
</template>

<script>
import { orderGoadd, orderOptions, receive } from '@/api/qyyorder.js'
import { dictType } from '@/api/notice.js'
import { Toast } from 'vant'
export default {
  data () {
    return {
      isShowcloseable: true,
      isShowbtn: true,
      query: {
        id: undefined, // 去向单id
        trafficComId: undefined, // 运输单位id
        trafficComName: undefined, // 运输单位名称
        handleWay: undefined, // 处理方式 通过字典拿的id
        receiveComId: undefined, // 接收单位id 根据处理方式id查询单位列表
        receiveComName: undefined, // 接收单位名称
        productName: undefined, // 废物名称
        signVolume: undefined, // 称重重量 小数后2位 (kg)
        uploadPaths: undefined, // 过磅单上传路径，多个以','分割
        remarks: undefined, // 备注
        orderList: [], // 选择订单
      },
      orderList: undefined,
      orderStatus: 5, // 订单状态 默认传5  4=部分收运完成,5=全部收运完成
      handleWay: undefined, // 处理方式
      receiveComId: undefined, // 接收单位
      uploadPaths: undefined, // 上传的图片
      arrUrl: [], // 保存图片url
      showPicker0: false,
      columns1: [],
      showPicker1: false,
      columns2: [],
      showPicker2: false,
      itemList: [], // 保存订单lsit
      categoryName: undefined, // 保存分类名称
    }
  },
  created() {
    this.orderOptions()
    this.dictType()
  },
  methods: {
    radioChange(item) { // 收运状态选择
      item.params = true
    },
    dictType() { // 字典
      dictType('handle_way').then(res => { // 处理方式
        if (res.data.code === 200) {
          this.columns1 = (res.data.data || []).map(item => {
            return {
              id: item.dictValue,
              text: item.dictLabel
            }
          })
        }
      })
    },
    receive() { // 接收单位选择框
      const obj = {
        handleWay: this.query.handleWay
      }
      receive(obj).then(res => {
        if (res.data.code === 200) {
          this.columns2 =  (res.data.data || []).map(item => {
            return {
              id: item.id,
              text: item.comName
            }
          })
        }
      })
    },
    orderOptions() { // 去向单List
      orderOptions().then(res => {
        if(res.data.code === 200) {
          this.itemList = res.data.data
          for (let i = 0; i < this.itemList.length; i++) {
            this.itemList[i].orderStatus = 5
          }
        }
      })
    },
    uploadDocuments(tempArr) { // 上传证件
      let client = new OSS.Wrapper({
        region: 'oss-cn-hangzhou',
        accessKeyId: 'LTAI5tRypK9PNyfDKPcabDhD',
        accessKeySecret: 'ykKGkcSM0ewsUcW4rAUd9htNOJwKVp',
        bucket: 'hzjiaduomei-huishou'
      })
      const Name = tempArr.name // 这是file里的name
      const suffix = Name.substr(Name.indexOf('.')) // 文件.后的后缀
      // 文件名:图片名字加上时间戳和9999以内随机数，防止重名
      const filename =`gygf/${new Date().getTime()}${Math.floor(Math.random() * 10000)}${suffix}`
      client.multipartUpload(filename, tempArr).then(res => { // 上传
        console.log('上传成功：', res)
        this.arrUrl.push(res.name)
        if (this.arrUrl.length == this.uploadPaths.length) {
          this.query.uploadPaths = this.arrUrl.join(',')
          orderGoadd(this.query).then(res => { // 提交信息接口
            if (res.data.code === 200) {
              Toast('添加完成')
              this.$router.go(-1)
            }
            this.isShowbtn = true
          })
        }
      }).catch(err => {
        console.log('上传失败：', err)
        this.isShowbtn = true
      })
    },
    onSubmit(file) { // 添加
      if (this.uploadPaths) {
        this.isShowbtn = false
        for (let i = 0; i < file.cardFront.length; i++) {
          this.uploadDocuments(file.cardFront[i].file)
        }
      } else {
        Toast('请上传过磅单')
      }
    },
    showPicker2Click() {
      if (this.query.handleWay) {
        this.showPicker2 = true
      } else {
        Toast('请先选择处理方式')
      }
    },
    onConfirm1(value) {
      this.handleWay = value.text
      this.query.handleWay = value.id
      this.showPicker1 = false
      this.receive()
    },
    onConfirm2(value) {
      this.receiveComId = value.text
      this.query.receiveComName = value.text
      this.query.receiveComId = value.id
      this.showPicker2 = false
    },
    showPicker0Click() {
      this.showPicker0 = true
      this.itemList.filter(item => {
        if (item.params) {
          this.isShowcloseable = false
        }
      })
    },
    confirmClick() { // 确认勾选
      this.query.orderList = this.itemList.filter(item => item.params).map(item => {
        return {
          id: item.id,
          orderStatus: item.orderStatus
        }
      })
      const arr = this.itemList.filter(item => item.params).map(item => item.categoryName)
      this.query.productName = arr.filter((item, index, self) => {
        return self.indexOf(item) == index
      }).join(',')
      if (this.query.orderList.length >= 1) {
        this.orderList = '已选择好订单'
      } else {
        this.orderList = ''
        this.isShowcloseable = true
      }
      this.showPicker0 = false
    },
    goBackClick() {
      this.$router.go(-1)
    }
  }
}

</script>
<style lang="scss" scoped>
.main {
  text-align: center;
  h3 {
    line-height: 50px;
  }
  .mainsc {
    min-height: 370px;
    max-height: 370px;
    overflow: scroll;
    .item-list {
      display: flex;
      align-items: center;
      padding: 5px 20px;
      margin-bottom: 10px;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      .right {
        margin-left: 10px;
        text-align: left;
        font-size: 13px;
        line-height: 23px;
      }
    }
  }
}
.btn {
  padding: 7.5px 30px;
}
::v-deep .van-radio__label {
  margin-left: 3px;
}
::v-deep .van-radio {
  align-items: center;
}
::v-deep .van-button--round {
  border-radius: 3px;
}
::v-deep .van-button--info {
  background-color: #0087f0;
  border: 1px solid #0087f0;
}
</style>
